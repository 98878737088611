<template>
  <div class="searchResult_container" ref="pageScroll">
    <div class="searchResult_title_block">
      <div class="searchResult_title" style="flex: 1 0 0">
        <img :src="require(`@/assets/images/pbx/phone.png`)" alt="" />号码
      </div>
      <div class="searchResult_title" style="flex: 24vw 0 0">
        <img :src="require(`@/assets/images/pbx/hospital.png`)" alt="" />医院
      </div>
      <div class="searchResult_title" style="flex: 22vw 0 0">
        <img :src="require(`@/assets/images/pbx/record.png`)" alt="" />流程状态
      </div>
      <div class="searchResult_title" style="flex: 26vw 0 0">
        <img :src="require(`@/assets/images/pbx/time.png`)" alt="" />流程时间
      </div>
    </div>
    <div class="searchResult_title_block global_full_screen">
      <itemScroll ref="itemScroll">
        <div>
          <div
            v-for="citems in data"
            :key="citems.id"
            class="searchResult_list_block"
          >
            <div class="searchResult_list" style="flex: 1 0 0">
              {{ citems.phone.substr(-4) }}
            </div>
            <div class="searchResult_list" style="flex: 24vw 0 0">
              {{ citems.hospital }}
            </div>
            <div class="searchResult_list" style="flex: 22vw 0 0">
              {{ getStatus(citems) }}
            </div>
            <div
              class="searchResult_list"
              style="flex: 26vw 0 0"
              v-if="citems.audit_status == 30"
            >
              请按审核意见尽快修改提交
            </div>
            <div v-else class="searchResult_list" style="flex: 26vw 0 0">
              <!-- {{ citems.create_time }} -->
              {{
                citems.audit_status == 20 && citems.sign_status === 0
                  ? ""
                  : citems.create_time.split(" ")[0]
              }}
              {{
                citems.audit_status === 15
                  ? "(提交录制时间)"
                  : citems.audit_status === 20 && citems.pay_status === 1
                  ? "（流程结束时间）"
                  : citems.audit_status === 20 && citems.sign_status === 1
                  ? "（预计流程结束时间）"
                  : ""
              }}
            </div>
          </div>
        </div>
      </itemScroll>
    </div>
  </div>
</template>
<script>
import itemScroll from "@/components/unit/itemScroll";
export default {
  name: "searchResult",
  data() {
    return {
      scroll: null,
      loading: false,
      phone: "",
      data: [],
      status: [
        "未创建", //0
        "已创建", //1
        "进行中", //2
        "审核中", //3
        "待签署", //4
        "审核未通过", //5
        "已签署", //6
        "流程已结束", //7
      ],
    };
  },
  created() {
    this.data = this.$tools.getStorage("searchResult", sessionStorage);
  },
  components: {
    itemScroll,
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "进度情况";
    this.$nextTick(() => {
      this.$refs.itemScroll.initScroll();
    });
  },
  methods: {
    getStatus(item) {
      if (item.audit_status === "") return this.status[0];
      if (item.audit_status === 0 && item.status === 0) return this.status[1];
      if (item.audit_status === 0 && item.status === 1) return this.status[2];
      if (item.audit_status === 15) return this.status[3];
      if (item.audit_status == 20 && item.pay_status === 1)
        return this.status[7];
      if (item.audit_status == 20 && item.sign_status === 0)
        return this.status[4];
      if (item.audit_status == 20 && item.sign_status === 1)
        return this.status[6];
      if (item.audit_status === 30) return this.status[5];
    },
  },
  beforeUnmount() {
    this.$tools.removeStorage("searchResult", sessionStorage);
  },
};
</script>
<style>
.searchResult_container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url("../../../assets/images/pbx/backgroud.png");
  background-size: 100% 100%;
  padding: 8vw 4vw;
}
.searchResult_title_block {
  display: flex;
  color: #004890;
  justify-content: space-between;
  margin-top: 2vw;
}
.searchResult_title {
  display: flex;
  justify-content: center;
  color: #004890;
  background: #fff;
  padding: 1vw;
  border-radius: 1vw;
  position: relative;
  margin-right: 2vw;
}
.searchResult_title:last-child {
  margin-right: 0;
}
.searchResult_title img {
  width: 6vw;
  height: auto;
  position: absolute;
  top: -5vw;
  left: 50%;
  transform: translateX(-50%);
}
.searchResult_list {
  display: flex;
  color: #fff;
  background: #b5ddf6;
  padding: 1vw;
  border-radius: 1vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 2vw;
}
.searchResult_list:last-child {
  margin-right: 0;
}
.searchResult_content {
  padding: 5.6vw 9.6vw;
}
.searchResult_list_block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vw;
}
</style>
