<template>
  <div class="searchInlet_container" ref="pageScroll">
    <div>
      <img
        :src="require('@/assets/images/pbx/banner.png')"
        class="searchInlet_cover"
        alt=""
      />

      <div class="searchInlet_content">
        <el-input placeholder="请输入手机号码后4位" v-model="phone"></el-input>
        <div class="searchInlet_button_group">
          <el-button class="searchInlet_button" @click="doSearch"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BSScroll from "better-scroll";
import { ElLoading, ElInput, ElButton } from "element-plus";
export default {
  name: "searchInlet",
  data() {
    return {
      scroll: null,
      loading: false,
      phone: "",
    };
  },
  created() {},
  components: {
    ElInput,
    ElButton,
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "录制进度查询入口";
    this.initScroll();
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BSScroll(this.$refs.pageScroll, {
            click: true,
            mouseWheel: true,
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    doSearch() {
      if (!this.phone) {
        this.$tips.error({ text: "请您输入正确号码" });
        return;
      }
      if (!/^\d{4}$/g.test(this.phone)) {
        this.$tips.error({ text: "请您输入正确号码" });
        return;
      }
      let loadingItem = ElLoading.service({
        lock: true,
        text: "搜索中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let url = this.$tools.getURL(this.$urls.search.doctorInfoEndNumber, {
        phone_endNumber: this.phone,
      });
      this.$axios
        .get(url)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          if (!res.data.length) {
            this.$tips.error({ text: "您所查询的账号，暂无数据" });
          } else {
            this.$tools.setStorage("searchResult", res.data, sessionStorage);
            this.$router.push({
              path: "/searchResult",
            });
          }
          loadingItem.close();
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          loadingItem.close();
        });
    },
  },
};
</script>
<style>
.searchInlet_container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgb(242, 242, 242);
}
.searchInlet_cover {
  width: 100%;
  height: auto;
}
.searchInlet_content .el-input__inner {
  text-align: center;
  background: #004890;
  color: #fff;
  font-size: 4vw;
  border-radius: 2vw;
  border: none !important;
}

.searchInlet_button_group {
  display: flex;
  justify-content: center;
  padding: 12vw 0 4vw;
}
.searchInlet_button {
  background: #004890 !important;
  color: #fff !important;
  border-radius: 2vw;
  border: none !important;
}

.searchInlet_content {
  padding: 5.6vw 9.6vw;
}
</style>
